import React from 'react'
import styled from 'styled-components'
import { MeiliSearch } from 'meilisearch'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'

import { baseUrl } from './Find'

import Box from '../../components/Box'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Link from '../../components/Link'
import Typography from '../../components/Typography'

import ApiKeyContext from '../../context/ApiKeyContext'
import EmailKeyContext from '../../context/EmailKeyContext'
import ClientContext from '../../context/ClientContext'


const ErrorMessage = styled(Typography)`
  position: absolute;
  left: 0;
  top: 32px;
`

const ApiKeyModal = ({ closeModal }) => {
  const { setISClient, setMSClient } = React.useContext(ClientContext)
  const { apiKey, setApiKey } = React.useContext(ApiKeyContext)
  const { emailValue, setEmailValue } = React.useContext(EmailKeyContext)
  const [value, setValue] = React.useState(apiKey || '')
  const [value2, setValue2] = React.useState(emailValue || '')
  const [error, setError] = React.useState()

  const updateClient = async () => {
    const clientToTry = new MeiliSearch({ host: baseUrl, apiKey: '88d2173a8923feab815e1afb43e2950f36ff9b4d41c4efce56b2707d5d74e67b' })
    // console.log('emailValue', value2)
    // console.log('apiKeyValueTest: ', value)
    try {
      await clientToTry.getIndexes()
      setApiKey(value)
      setEmailValue(value2)
      setISClient(instantMeiliSearch(baseUrl, value))
      setMSClient(clientToTry)
      closeModal()
      setError()
    } catch (err) {
      setError(err.message)
    }
  }

  React.useEffect(() => {
    setValue(apiKey)
  }, [apiKey])

  React.useEffect(() => {
    setValue2(emailValue)
  }, [emailValue])

  return (
    <>
      <Box display="flex">
        {/* <Input
          style={{ display: 'flex', height: 34 }}
          name="emailKey"
          type="text"
          placeholder="youremail@domain.com"
          value={value2}
          onChange={(e) => setValue2(e.target.value)}
        /> */}
        <Input
          style={{ display: 'flex', height: 34 }}
          name="apiKey"
          type="text"
          placeholder="88d2173a8923feab815e1afb43e2950f36ff9b4d41c4efce56b2707d5d74e67b"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button
          variant="filled"
          size="small"
          onClick={() => updateClient()}
          style={{ minWidth: 'auto', width: 48, marginLeft: 16 }}
        >
          Go
        </Button>
      </Box>
      <Box position="relative">
        {/* <Typography variant="typo11" my={3} color="gray.6" mt={2}>
          Please enter your email and API key found at the{' '}
          <Link href="https://members.nhit.org/" target="_blank">
            NHIT membership site
          </Link>{' '}
        </Typography> */}
        {error && (
          <ErrorMessage variant="typo11" color="#DC143C">
            <br />
            {error}
          </ErrorMessage>
        )}
      </Box>
    </>
  )
}

export default ApiKeyModal
