// import { initializeApp } from "firebase/app";
// import { getFirestore, collection, addDoc } from "firebase/firestore";


// const firebaseConfig = {
//   apiKey: 'AIzaSyD-zoJSQg03hOnU2YBp3KavtnpJ2Cs7R4g',
//   authDomain: 'nhit-search.firebaseapp.com',
//   projectId: 'nhit-search',
//   storageBucket: 'nhit-search.appspot.com',
//   messagingSenderId: '130712117831',
//   appId: '1:130712117831:web:97549a351043133d58c762',
//   measurementId: 'G-S6ZXJXRNZN'
// }

// // Initialize Firebase
// const firebaseApp = initializeApp(firebaseConfig);
// const db = getFirestore(firebaseApp);

// // write a async function to add a doc to the collection
// const addDocToCollection = async () => {
//     try {
//         const docRef = await addDoc(collection(db, "phq9"), {
//         q1: "55",
//         q2: "3",
//         q3: "34",
//         q34: "434"
//         });
//         console.log("Document written with ID: ", docRef.id);
//     } catch (e) {
//         console.error("Error adding document: ", e);
//     }
// }

// addDocToCollection()

import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: 'AIzaSyD-zoJSQg03hOnU2YBp3KavtnpJ2Cs7R4g',
  authDomain: 'nhit-search.firebaseapp.com',
  projectId: 'nhit-search',
  storageBucket: 'nhit-search.appspot.com',
  messagingSenderId: '130712117831',
  appId: '1:130712117831:web:97549a351043133d58c762',
  measurementId: 'G-S6ZXJXRNZN'

};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};