import React from 'react'

const EmailKeyContext = React.createContext({
  emailValue: '',
  setEmailValue: () => {},
})

export const EmailKeyProvider = EmailKeyContext.Provider
export const EmailKeyConsumer = EmailKeyContext.Consumer

export default EmailKeyContext
