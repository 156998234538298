import * as React from 'react'
import nhitLogoPng from'./svg/nhitLogo.png';

function Nhitlogo({ title, titleId, ...props }) {
  return (

  <img  
    src={nhitLogoPng} 
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
  </img>
  

  

  )
}

export default Nhitlogo
