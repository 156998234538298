import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginMui from "./components/Login/LoginMui";
import RegisterMui from "./components/Register/RegisterMui";
import ResetMui from "./components/Reset/ResetMui";
import Find from "./components/Find/Find";

import AppOld from "./App_OLD"; 


function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route exact path="/" element={<LoginMui />} />
          <Route exact path="/register" element={<RegisterMui />} />
          <Route exact path="/reset" element={<ResetMui />} />
          <Route exact path="/find" element={<Find />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;